import React from "react"
import { IoMdClose } from "react-icons/io"
import GreenLine from "../../layout/greenLine"
import BlackOverlay from "../../layout/blackOverlay"

const ModalContainer = ({ className = "", visible = true, nestedModal = false, onClose, children }) => {
  if (!visible) return null

  return (
    <>
      <BlackOverlay onClose={onClose} />
      <div
        className={`absolute top-0 left-0 w-full h-full flex justify-center items-center`}
        // style={{ marginTop: nestedModal ? 0 : window.pageYOffset }}
      >
        <div className={`bg-white flex flex-col rounded-t shadow z-50 ${className}`}>
          <GreenLine />
          <IoMdClose
            className="self-end m-4 cursor-pointer hover:bg-gray-100 rounded-full"
            size={22}
            onClick={e => {
              e.stopPropagation()
              onClose()
            }}
          />
          {children}
        </div>
      </div>
    </>
  )
}

export default ModalContainer
