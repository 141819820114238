import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { FiMenu } from "react-icons/fi"
import { MdExitToApp } from "react-icons/md"
import Link from "../layout/link"
import Button from "../base/buttons/icon"
import MobileMenu from "./components/mobileMenu"
import useLogout from "../../utils/hooks/useLogout"
import ConfirmModal from "../base/modals/confirmModal"
import useUser from "../../utils/hooks/useUser"
import { navigate } from "gatsby-plugin-intl"
import notifications from "../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../utils/hooks/useFormatMessage"
import { useCompanyLogo } from "../../utils/hooks/useCompanyLogo"
import { useLocalStorage } from "react-use"

const messages = defineMessages({
  logoutModalTitle: "Are you sure you want to sign out of Atolo?",
  logoutModalLogoutButton: "Yes sign me out",
})

// Note: User should be given as prop to header so that we can use info in user drop down
const Header = ({ noOptions, options, type }) => {
  const [showMenuScreen, setShowMenuScreen] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [isSelected, setIsSelected] = useState()
  const formatMessage = useFormatMessage()

  const { user, company } = useUser()
  const { logout: executeLogout } = useLogout()
  const headerImage = useCompanyLogo()

  const toggleMenuScreen = () => setShowMenuScreen(!showMenuScreen)

  /* Determine what header element is selected based on current url. 
     We use this method in a use effect based on pathname changes. */
  useEffect(() => {
    if (window) {
      setIsSelected({
        func: option => {
          return (
            window.location.pathname === option.href ||
            window.location.pathname === option.href + "/" ||
            window.location.pathname === "/fr" + option.href ||
            "/fr" + window.location.pathname === option.href + "/" ||
            window.location.pathname === "/en" + option.href ||
            "/en" + window.location.pathname === option.href + "/" ||
            window.location.pathname === "/nl" + option.href ||
            "/nl" + window.location.pathname === option.href + "/" ||
            window.location.pathname === "/de" + option.href ||
            "/de" + window.location.pathname === option.href + "/"
          )
        },
      })
    }
  }, [window])

  const navigateHome = () => {
    if (type === "hr") {
      navigate("/admin")
    } else {
      navigate("/atolo")
    }
  }
  const [v, s, removeCache] = useLocalStorage("invites-form-state", "")
  const handleLogin = async () => {
    try {
      await executeLogout()
      removeCache()
      if (type === "hr") {
        navigate("/admin/login")
      } else {
        navigate("/atolo/login")
      }
      setShowLogoutModal(false)
    } catch (error) {
      notifications.error("Oops! Something went wrong.", error.message, error.details)
    }
  }

  return (
    <>
      <div className="max-w-full hidden-print flex items-center justify-center  bg-white  h-16 border-b py-3">
        <div className="container flex items-center max-w-4xl w-4/5 justify-between">
          <a onClick={navigateHome}>
            {headerImage && (
              <img
                className="p-0 m-0 h-16 cursor-pointer"
                style={{ display: "block", width: 120, height: 80, objectFit: "contain" }}
                src={headerImage}
                alt="atolo"
              />
            )}
            {!headerImage && <div style={{ width: 120, height: 80 }} />}
          </a>
          {!noOptions && (
            <>
              <div className="hidden md:flex items-center">
                {options.map((option, i) => {
                  return (
                    <Link
                      Icon={option.icon}
                      text={option.text}
                      selected={isSelected && isSelected.func(option)}
                      href={option.href}
                      key={`${option.text}-${i}`}
                    />
                  )
                })}
              </div>

              <div className="hidden md:flex items-center">
                <div
                  className="border border-gray-300 rounded px-3 py-1 cursor-pointer flex flex-row items-center hover:bg-white"
                  onClick={() => setShowLogoutModal(true)}
                >
                  <p className="text-sm font-medium text-gray-700">
                    {user && user.firstname} {company && `(${company.companyname})`}
                  </p>
                  <div className="pl-2 text-red-700">
                    <MdExitToApp size={14} />
                  </div>
                </div>
              </div>
              {/* small screen stuff */}
              <div className="flex items-center justify-between md:hidden">
                <Button onClick={toggleMenuScreen}>
                  <FiMenu />
                </Button>
              </div>
              {showMenuScreen && (
                <div className="absolute left-0 top-0 w-full min-h-screen bg-white z-50">
                  <MobileMenu onClose={toggleMenuScreen} options={options} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ConfirmModal
        visible={showLogoutModal}
        title={formatMessage(messages.logoutModalTitle)}
        confirmText={formatMessage(messages.logoutModalLogoutButton)}
        onClose={() => setShowLogoutModal(false)}
        onConfirm={handleLogin}
      />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
