export const setUser = user => {
  return {
    type: "account/SET_USER",
    payload: user,
  }
}

export const setCompany = company => ({
  type: "account/SET_COMPANY",
  payload: company,
})

export const setSessionToken = sessionToken => ({
  type: "account/SET_SESSION_TOKEN",
  payload: sessionToken,
})

export const onLogout = () => ({
  type: "LOGOUT",
})
