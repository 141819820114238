import React from "react"
import ModalContainer from "./modalContainer"

// import Button from "../buttons/primary"

const ConfirmModal = ({ visible, nestedModal = false, title, confirmText, onConfirm, onClose }) => {
  return (
    <ModalContainer nestedModal={nestedModal} visible={visible} onClose={onClose}>
      <div className="px-10 pb-12">
        <p className="text-lg font-medium text-gray-800 pt-2">{title}</p>
        <div className="flex flex-row justify-between mt-8">
          <p
            className="text-gray-500 cursor-pointer"
            onClick={e => {
              e.stopPropagation()
              onClose()
            }}
          >
            Cancel
          </p>
          <p
            className="text-red-700 font-medium cursor-pointer"
            onClick={e => {
              e.stopPropagation()
              onConfirm()
            }}
          >
            {confirmText}
          </p>
        </div>
      </div>
    </ModalContainer>
  )
}

export default ConfirmModal
