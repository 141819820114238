import React from "react"
import { FiCircle } from "react-icons/fi"
import { Link as GLink } from "gatsby-plugin-intl"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const Link = ({ text = "Click me", Icon = FiCircle, selected, onClick, href, highlighted }) => {
  const { regular } = useThemeColors()
  return (
    <GLink to={href} onClick={onClick}>
      <div
        className={`flex mx-2 pr-2 items-center justify-center cursor-pointer py-1 rounded hover:text-${regular} ${
          selected ? `text-${regular}` : "text-gray-600"
        } ${highlighted ? `bg-${regular}` : "bg-white"}`}
        onClick={onClick}
      >
        <div className={`px-2`}>
          <Icon />
        </div>
        <p className={`text-sm font-medium text-center`}>{text}</p>
      </div>
    </GLink>
  )
}

export default Link
