import React from "react"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const GreenLine = ({ className, greenLinePercentage = 1, height = 1 }) => {
  const { regular } = useThemeColors()
  return (
    <div className={`h-${height} w-full bg-gray-300 rounded`}>
      <div className={`h-full w-full bg-${regular} rounded ${className}`} style={{ width: `${Math.min(greenLinePercentage, 1) * 100}%` }} />
    </div>
  )
}
export default GreenLine
