import { useSelector } from "react-redux"
import queryString from "query-string"
import { invitationSelector } from "../../store/invitationByToken/selectors"
import useUser from "./useUser"
import { levelEvaluationByTokenSelector } from "../../store/levelEvaluationByToken/selectors"

export const useCompanyUUID = () => {
  const { user } = useUser()

  // levelevaluation token is used to get company uuid (first test page)
  const evalToken = queryString.parse(window.location.search)?.levelevaluation ?? queryString.parse(window.location.search)?.token
  const { result: levelevaluation } = useSelector(levelEvaluationByTokenSelector(evalToken))

  // URL token is used to get company uuid (invitation page)
  const invToken = queryString.parse(window.location.search)?.token
  const { result: invitation } = useSelector(invitationSelector(invToken))

  if (evalToken && levelevaluation?.hr?.companyuuid) {
    return levelevaluation?.hr?.companyuuid
  }

  if (invToken && invitation?.companyuuid) {
    return invitation.companyuuid
  }

  // If we have a token, assume we are still waiting to fetch the corresponding invitation/evaluation. Return "loading" so that hooks can act on this.
  if (evalToken || invToken) {
    return "loading"
  }

  if (user) {
    return user.companyuuid
  }

  return "none"
}
