import React from "react"
import { ClipLoader } from "react-spinners"

const Icon = ({ type, children, onClick, loading }) => {
  let color = "text-gray-500"
  if (type === "error") {
    color = "text-red-700"
  }
  if (type === "edit") {
    color = "text-blue-700"
  }
  return (
    <div className={`flex justify-between items-center mx-1 p-2 rounded border cursor-pointer ${color}`} onClick={onClick}>
      {children}
      {loading && <ClipLoader size={10} color="#ddd" />}
    </div>
  )
}

export default Icon
