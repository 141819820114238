import { getPerson } from "src/api"
import { onLogout } from "../account/actions"

export const setPerson = (personUUID, person) => ({
  type: "person/SET_PERSON",
  personUUID,
  payload: person,
})

export const setLoading = (personUUID, loading) => ({
  type: "person/SET_LOADING",
  personUUID,
  payload: loading,
})

export const setError = (personUUID, error) => ({
  type: "person/SET_ERROR",
  personUUID,
  payload: error,
})

export const fetchPerson = personUUID => async dispatch => {
  dispatch(setLoading(personUUID, true))
  try {
    const person = await getPerson(personUUID)
    dispatch(setPerson(personUUID, person))
  } catch (error) {
    dispatch(setError(personUUID, error))
    if ((error.code = "ForbiddenAccess" && error.message === "No valid authentication")) {
      dispatch(onLogout())
    }
  } finally {
    dispatch(setLoading(personUUID, false))
  }
}
