import { getPersonList } from "src/api"
import { onLogout } from "../account/actions"

export const setPersons = (companyUUID, persons) => ({
  type: "persons/SET_PERSONS",
  companyUUID,
  payload: persons,
})

export const setLoading = (companyUUID, loading) => ({
  type: "persons/SET_LOADING",
  companyUUID,
  payload: loading,
})

export const setError = (companyUUID, error) => ({
  type: "persons/SET_ERROR",
  companyUUID,
  payload: error,
})

export const fetchPersons = companyUUID => async dispatch => {
  dispatch(setLoading(companyUUID, true))
  try {
    const persons = await getPersonList(companyUUID)
    dispatch(setPersons(companyUUID, persons))
  } catch (error) {
    dispatch(setError(companyUUID, error))
    if ((error.code = "ForbiddenAccess" && error.message === "No valid authentication")) {
      dispatch(onLogout())
    }
  } finally {
    dispatch(setLoading(companyUUID, false))
  }
}
