export const getAPI_CALL_STARTED = nameOfCall => ({
  category: "API_CALL",
  // string - required - Type of interaction (e.g. 'play')
  action: "API_CALL_STARTED",
  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
  label: nameOfCall,
})

export const getAPI_CALL_SUCCES = nameOfCall => ({
  category: "API_CALL",
  // string - required - Type of interaction (e.g. 'play')
  action: "API_CALL_SUCCES",
  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
  label: nameOfCall,
})

export const getAPI_CALL_FAIL = nameOfCall => ({
  category: "API_CALL",
  // string - required - Type of interaction (e.g. 'play')
  action: "API_CALL_FAIL",
  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
  label: nameOfCall,
})
