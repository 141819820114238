import React from "react"

const BlackOverlay = ({ onClose, visible = true, transparent, children = null }) => {
  if (!visible) return null

  return (
    <div
      className={`fixed w-full h-full top-0 left-0 bg-black ${transparent ? "opacity-0" : "opacity-75"} z-10`}
      onClick={e => {
        e.stopPropagation()
        onClose()
      }}
    >
      {children}
    </div>
  )
}

export default BlackOverlay
