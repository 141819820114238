import { useState } from "react"
import { patchPerson } from "src/api"
import { fetchPerson } from "../../store/person/actions"
import { useDispatch } from "react-redux"
import { fetchCompany } from "../../store/company/actions"
import { fetchPersons } from "../../store/persons/actions"
import { fetchLevelEvaluations } from "../../store/levelEvaluations/actions"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const execute = async person => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("UPDATE_PERSON"))

      setLoading(true)
      setError(undefined)
      const data = await patchPerson({ ...person, commonname: `${person.firstname} ${person.lastname}` })
      dispatch(fetchPerson(person.uuid))
      dispatch(fetchPersons(person.companyuuid))
      dispatch(fetchCompany(person.companyuuid))
      dispatch(fetchLevelEvaluations(person.companyuuid))
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, patchPerson: execute }
}
