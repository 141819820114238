import React, { useEffect } from "react"
import { Link } from "gatsby-plugin-intl"
import { getLanguageCode } from "../../utils/language"
import { changeLocale } from "gatsby-plugin-intl"
import { useIntl } from "gatsby-plugin-intl"
import usePatchPerson from "../../utils/hooks/usePatchPerson"
import notifications from "../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../utils/hooks/useFormatMessage"
import useUser from "../../utils/hooks/useUser"
import _ from "lodash"
import { setUser } from "../../store/account/actions"
import { useDispatch } from "react-redux"
import { GiEarthAfricaEurope } from "react-icons/gi"
import atoloLogo from "src/images/logos/logo.png"

const messages = defineMessages({
  changeLanguageErrorNotificationTitle: "Oops! Something went wrong.",
})

const Footer = () => {
  const intl = useIntl()
  const user = useUser()
  const dispatch = useDispatch()

  const { patchPerson } = usePatchPerson()
  const formatMessage = useFormatMessage()

  useEffect(() => {
    const commlangcode = _.get(user, "user.commlangcode")
    if (commlangcode) {
      changeLocale(commlangcode)
    }
  }, [user?.user?.commlangcode])

  const changeLanguage = async language => {
    if (user && user.user && user.user.commlangcode) {
      try {
        const pers = await patchPerson({ ...user.user, commlangcode: language })
        dispatch(setUser(pers, "footer"))
      } catch (error) {
        notifications.error(formatMessage(messages.changeLanguageErrorNotificationTitle), error.message)
      }
    } else {
      changeLocale(language)
    }
  }

  return (
    <div className="max-w-full flex hidden-print  text-2xs flex-row items-center justify-center leading-tight text-gray-600 bg-gray-100 border-t px-6">
      <div className="container flex justify-between items-center m-2 max-w-4xl w-4/5">
        <img className="w-24 mr-4" alt="Atolo" src={atoloLogo} />
        <div className="flex flex-1 m-2 md:flex-row md:items-center">
          <div className="opacity-100 hidden md:block">
            Property Of Atolo SA/NV
            <br /> Avenue de Tervurenlaan 36/18, <br />
            1040 Brussels
            <br /> Tel: +32 2 609 50 90
          </div>
          <div className="opacity-100 ml-3 hidden md:block">
            Atolo Sàrl
            <br />
            Rue du Petit-Chêne 18ter, <br />
            1003 Lausanne
            <br />
            Tel: +41 21 806 55 55 | +41 78 736 92 58
          </div>
        </div>
        <div className="flex flex-col justify-baseline items-end">
          <div className="flex flex-row flex-1 items-center">
            <GiEarthAfricaEurope size={16} className="mr-1" />
            {options.map(option => (
              <div
                key={option.text}
                className={`flex items-center  cursor-pointer p-1 border rounded border-gray-100 ${
                  intl.locale === option.text.toLowerCase() ? "border-gray-700" : ""
                }`}
              >
                <div
                  className={`h-2 flex items-center `}
                  onClick={() => {
                    changeLanguage(option.text.toLowerCase())
                  }}
                >
                  {option.text}
                </div>
              </div>
            ))}
          </div>
          <div className="mr-1 my-1">
            <Link to="/disclaimer">Disclaimer</Link>
          </div>
          <div className="mr-1 mb-1">
            <Link to="/disclaimer">Cookies</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer

const options = [
  {
    text: getLanguageCode("EN"),
    onClick: () => {},
  },
  {
    text: getLanguageCode("FR"),
    onClick: () => {},
  },
  {
    text: getLanguageCode("NL"),
    onClick: () => {},
  },
  {
    text: getLanguageCode("DE"),
    onClick: () => {},
  },
]
