import { getLevelEvaluationList } from "src/api"
import { onLogout } from "../account/actions"

export const setLevelEvaluations = (companyUUID, levelEvaluations) => ({
  type: "levelEvaluations/SET_LEVEL_EVALUATIONS",
  companyUUID,
  payload: levelEvaluations,
})

export const setLoading = (companyUUID, loading) => ({
  type: "levelEvaluations/SET_LOADING",
  companyUUID,
  payload: loading,
})

export const setError = (companyUUID, error) => ({
  type: "levelEvaluations/SET_ERROR",
  companyUUID,
  payload: error,
})

export const fetchLevelEvaluations = companyUUID => async dispatch => {
  dispatch(setLoading(companyUUID, true))
  try {
    const levelEvaluations = await getLevelEvaluationList(companyUUID)
    dispatch(setLevelEvaluations(companyUUID, levelEvaluations))
  } catch (error) {
    dispatch(setError(companyUUID, error))
    if ((error.code = "ForbiddenAccess" && error.message === "No valid authentication")) {
      dispatch(onLogout())
    }
  } finally {
    dispatch(setLoading(companyUUID, false))
  }
}
