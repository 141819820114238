import { useIntl } from "gatsby-plugin-intl"

export default () => {
  const intl = useIntl()
  return (...args) => {
    try {
      return intl.formatMessage(...args)
    } catch (err) {
      return "NO_TEXT_FOUND"
    }
  }
}
