import { useCompanyUUID } from "./useCompanyUUID"

export const useThemeColors = () => {
  const uuid = useCompanyUUID()

  if (
    uuid === "dba60e20-cae2-4f6b-8062-bc49a685d3fa" ||
    uuid === "8c8039e8-23f5-407f-b175-32254270c4f3" ||
    uuid === "88ef5953-f518-42f8-8a2d-5416873955a8"
  ) {
    return {
      regular: "protectas",
      dark: "protectasdark",
      highlight: "red-500",
    }
  }

  return {
    regular: "atolo",
    dark: "atolodark",
    highlight: "atolo",
  }
}
