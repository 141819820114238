import { getCompany } from "src/api"

export const setCompany = (companyUUID, company) => {
  return {
    type: "company/SET_COMPANY",
    companyUUID,
    payload: company,
  }
}

export const setLoading = (companyUUID, loading) => ({
  type: "company/SET_LOADING",
  companyUUID,
  payload: loading,
})

export const setError = (companyUUID, error) => ({
  type: "company/SET_ERROR",
  companyUUID,
  payload: error,
})

export const fetchCompany = companyUUID => async dispatch => {
  dispatch(setLoading(companyUUID, true))
  try {
    const company = await getCompany(companyUUID)
    dispatch(setCompany(companyUUID, company))
  } catch (error) {
    dispatch(setError(companyUUID, error))
  } finally {
    dispatch(setLoading(companyUUID, false))
  }
}
