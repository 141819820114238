/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Header from "../components/header"
import { FiPlusSquare, FiUsers, FiHome } from "react-icons/fi"
import ReactNotification from "react-notifications-component"
import "./layout.css"
import Footer from "./layout/footer"

import background from "../images/backgrounds/crossword.png"
import "./notifications.css"
import "./animate.css"
import ErrorBoundary from "./errorBoundary"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../utils/hooks/useFormatMessage"

import "./picker.css"

const messages = defineMessages({
  headerAdminParticipantsTab: "Participants",
  headerAdminInvitationsTab: "Invitations",
  headerAdminSendRequestsTab: "Send requests",
  headerAdminSendInvitationsTab: "Send invitations",
  headerAdminYourCompanyTab: "Your Company",
  headerAtoloClientsTab: "Clients",
  headerAtoloCreateClientTab: "Create New Client",
  headerAtoloAdminsTab: "Admins",
})

const Layout = ({ children, noHeaderOptions, type, onSearch, searchQuery, stretchBackground }) => {
  const formatMessage = useFormatMessage()

  /* Note: the on search method receives an onChange event from the text input field as a parameter. */
  const headerOptionsHRManager = [
    {
      icon: FiUsers,
      text: formatMessage(messages.headerAdminParticipantsTab),
      href: "/admin",
    },
    {
      icon: FiPlusSquare,
      text: formatMessage(messages.headerAdminSendRequestsTab),
      href: "/admin/invites",
    },
    {
      icon: FiPlusSquare,
      text: formatMessage(messages.headerAdminSendInvitationsTab),
      href: "/admin/invitations",
    },
    {
      icon: FiHome,
      text: formatMessage(messages.headerAdminYourCompanyTab),
      href: "/admin/settings",
    },
  ]

  const headerOptionsatolo = [
    {
      icon: FiUsers,
      text: formatMessage(messages.headerAtoloClientsTab),
      href: "/atolo",
      onSearch: () => {},
    },
    {
      icon: FiPlusSquare,
      text: formatMessage(messages.headerAtoloCreateClientTab),
      href: "/atolo/create-client",
    },
    {
      icon: FiUsers,
      text: formatMessage(messages.headerAtoloAdminsTab),
      href: "/atolo/admins",
      onSearch: () => {},
    },
  ]
  const headerParams = type === "hr" ? headerOptionsHRManager : headerOptionsatolo

  return (
    <ErrorBoundary>
      <Container className={`flex flex-col flex-1 w-full ${stretchBackground ? "h-full" : ""} font-sans`}>
        <ReactNotification />
        <Header noOptions={noHeaderOptions} options={headerParams} type={type} onSearch={onSearch} searchQuery={searchQuery} />
        <Background className="flex flex-col flex-grow w-full h-full items-center md:justify-center">{children}</Background>
        <Footer />
      </Container>
    </ErrorBoundary>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Background = styled.div`
  background: url(${background});
  @media print {
    background: white;
  }
  background-repeat: repeat;
`

const Container = styled.div`
  min-height: 100vh;
`
