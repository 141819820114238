import logo from "../../images/logos/logo.png"
import logo2 from "../../images/logos/logo2.png"
import protectas from "../../images/logos/protectas.png"
import { useCompanyUUID } from "./useCompanyUUID"

const getHeaderImage = companyUUID => {
  // Devizer company
  if (companyUUID === "dba60e20-cae2-4f6b-8062-bc49a685d3fa") {
    return logo2
  }

  // Protectas (CH) Language Evaluation
  if (companyUUID === "8c8039e8-23f5-407f-b175-32254270c4f3") {
    return logo2
  }

  // Protectas (HQ) Language Evaluation
  if (companyUUID === "88ef5953-f518-42f8-8a2d-5416873955a8") {
    return logo2
  }

  if (companyUUID === "loading") {
    return undefined
  }

  return logo
}
const getMainImage = companyUUID => {
  // Devizer company
  if (companyUUID === "dba60e20-cae2-4f6b-8062-bc49a685d3fa") {
    return protectas
  }

  // Protectas (CH) Language Evaluation
  if (companyUUID === "8c8039e8-23f5-407f-b175-32254270c4f3") {
    return protectas
  }

  // Protectas (HQ) Language Evaluation
  if (companyUUID === "88ef5953-f518-42f8-8a2d-5416873955a8") {
    return protectas
  }

  if (companyUUID === "loading") {
    return undefined
  }

  return undefined
}

export const isCustomCompanyLogo = uuid => {
  return (
    // Devizer company
    uuid === "dba60e20-cae2-4f6b-8062-bc49a685d3fa" ||
    // Protectas (CH) Language Evaluation
    uuid === "8c8039e8-23f5-407f-b175-32254270c4f3" ||
    // Protectas (HQ) Language Evaluation
    uuid === "88ef5953-f518-42f8-8a2d-5416873955a8"
  )
}

export const useCompanyLogo = () => {
  const uuid = useCompanyUUID()

  return getHeaderImage(uuid)
}

export const useIntroLogo = () => {
  const uuid = useCompanyUUID()

  return getMainImage(uuid)
}
