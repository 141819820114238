import React from "react"
import { IoMdClose } from "react-icons/io"
import Link from "../../layout/link"
import logo from "../../../images/logos/logo.png"
import { useThemeColors } from "src/utils/hooks/useThemeColors"
const MobileMenu = ({ className, onClose, options }) => {
  const { regular } = useThemeColors()
  return (
    <>
      <div className={`h-1 w-full self-start bg-${regular}`} />
      <div className="flex justify-end mt-2 ">
        <IoMdClose size={22} className="m-2 cursor-pointer" onClick={onClose} />
      </div>

      <div className={`flex flex-col items-center justify-between p-5 mt-5 ${className}`}>
        <img className="p-0 -mb-10 h-32" src={logo} alt="atolo" />
        {options.map(option => {
          return (
            <div key={option.text} className="mt-20 w-full text-center  text-gray-800 text-2xl font-sans font-semibold p-2  rounded-2xl  ">
              <Link Icon={option.icon} text={option.text} href={option.href} />
            </div>
          )
        })}
      </div>
      <div className="text-center text-xs mt-10 text-gray-700 absolute bottom-0 mb-2 w-full">Copyright &copy; Atolo 2022</div>
    </>
  )
}
export default MobileMenu
